<template>
    <aside class="side-bar d-flex flex-column justify-content-start justify-content-xl-center py-2 py-xl-3 pl-1 vh-100 overflow-auto">
        <router-link class="btn btn-info border-0 mb-3"
                     exact-active-class="active"
                     to="/">
            <span class="d-block h3 text-center">
                 <font-awesome-icon v-bind:icon="['fad', 'gauge']"></font-awesome-icon>
            </span>
            Dashboard
        </router-link>
        <router-link class="btn btn-info border-0 mb-3"
                     active-class="active"
                     to="/chats">
            <span class="d-block h3 text-center">
                 <font-awesome-icon v-bind:icon="['fad', 'comments', ]"></font-awesome-icon>
            </span>
            Chats & Groups
        </router-link>
        <router-link class="btn btn-info border-0 mb-3"
                     active-class="active"
                     to="/users">
            <span class="d-block h3 text-center">
                 <font-awesome-icon v-bind:icon="['fad', 'users']"></font-awesome-icon>
            </span>
            People
        </router-link>
        <router-link class="btn btn-info border-0 mb-3"
                     active-class="active"
                     to="/events">
            <span class="d-block h3 text-center">
                 <font-awesome-icon v-bind:icon="['fad', 'calendar-star', ]"></font-awesome-icon>
            </span>
            Events
        </router-link>
        <router-link class="btn btn-info border-0 mb-3"
                     active-class="active"
                     to="/promotions">
            <span class="d-block h3 text-center">
                 <font-awesome-icon v-bind:icon="['fad', 'ad', ]"></font-awesome-icon>
            </span>
            Promos
        </router-link>
        <router-link class="btn btn-info border-0 mb-3"
                     active-class="active"
                     to="/settings">
            <span class="d-block h3 text-center">
                 <font-awesome-icon v-bind:icon="['fad', 'cogs', ]"></font-awesome-icon>
            </span>
            Settings
        </router-link>

        <!-- <router-link class="btn btn-info border-0 mb-3"
                     active-class="active"
                     to="/config">
            <span class="d-block h3 text-center">
                 <font-awesome-icon icon="mobile"></font-awesome-icon>
            </span>
            App Config
        </router-link> -->

        <button class="btn btn-info bg-transparent border-0 mb-3"
                v-on:click="logout">
            <span class="d-block h3 text-center">
                <font-awesome-icon v-bind:icon="['fad', 'sign-out-alt', ]"></font-awesome-icon>
            </span>
            Logout
        </button>

        <button-account class="position-absolute btn-account"></button-account>
    </aside>
</template>

<script>
import ButtonAccount from "@/components/layout/SideBar/ButtonAccount";
export default {
    name: "SideBar",
    components: {
        ButtonAccount,
    },
    data () {
        return {

        };
    },
    computed: {
        currentRegion () {
            return this.$route.params?.region ?? "HKG";
        },
        currentRouteName () {
            return this.$route?.name ?? "";
        },
    },
    methods: {
        logout () {
            this.$store.dispatch("admin/logout");
            this.$router.push({
                name: "login",
            });
        },
    },
    mounted () {
        document
            .getElementById("app")
            .addEventListener(
                "click",
                (evt) => {
                    if (
                        !evt.target.matches(".side-bar") &&
                        !evt.target.matches(".side-bar *") &&
                        !evt.target.matches(".btn-toggle-side-bar") &&
                        !evt.target.matches(".btn-toggle-side-bar *")
                    ) {
                        this.$store.commit("layout/closeSideBar");
                    }
                }
            );
    }
}
</script>

<style lang="scss" scoped>
.side-bar {
    min-width: calc(100px + 0.25rem);

    .btn {
        width: 100px;
        border-top-right-radius: 1rem;
        border-bottom-right-radius: 1rem;
        transition:
            transform 300ms ease-in-out,
            box-shadow 300ms ease-in-out,
            font-weight 150ms ease-in-out;

        &.active {
            transform: translate(-10px);
            box-shadow: 0 1rem 3rem rgba(255,255,255,.075);
            font-weight: 700;
        }
    }
}

.btn-account {
    bottom: 0;
}
</style>
