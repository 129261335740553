<template>
    <b-modal size="lg"
             centered
             hide-header
             hide-footer
             content-class="shadow border-0"
             v-bind:visible="value"
             v-on:hide="hideModal"
             v-on:cancel="hideModal"
             v-on:close="hideModal">
        <h1 class="font-weight-bolder mb-0">
            Edit Account
        </h1>
        <p class="mb-4">
            Editing account here will alter displayed info.
        </p>

        <form class="form-row"
              v-on:submit.prevent="save"
              v-on:reset.prevent="reset">
            <div class="col-12 text-center mb-3">
                <div class="mb-2">
                    <b-avatar size="7rem"
                              v-bind:src="avatar">
                    </b-avatar>
                </div>
                <label for="avatar">
                    <a class="btn btn-sm btn-light border">
                        👤 Select avatar
                    </a>
                    <input id="avatar"
                           class="d-none"
                           type="file"
                           multiple
                           v-on:change="handleAvatar">
                </label>
            </div>

            <div class="col-12">
                <b-form-group>
                    <template v-slot:label>
                        Company name
                    </template>
                    <b-form-input class="bg-light"
                                  type="text"
                                  required
                                  v-model="company">
                    </b-form-input>
                </b-form-group>
            </div>

            <div class="col-12">
                <b-form-group>
                    <template v-slot:label>
                        User name
                    </template>
                    <b-form-input class="bg-light"
                                  type="text"
                                  required
                                  v-model="username">
                    </b-form-input>
                </b-form-group>
            </div>

            <div class="col-md-6">
                <b-form-group>
                    <template v-slot:label>
                        Email
                    </template>
                    <b-form-input class="bg-light"
                                  type="email"
                                  required
                                  v-model="email">
                    </b-form-input>
                </b-form-group>
            </div>
            <div class="col-md-6">
                <b-form-group>
                    <template v-slot:label>
                        Phone
                    </template>
                    <b-input-group>
                        <template v-slot:prepend>
                            <b-dropdown variant="info"
                                        v-bind:text="countryCode">
                                <div class="dropdown-item">
                                    🇭🇰 +852
                                </div>
                            </b-dropdown>
                        </template>
                        <b-form-input class="bg-light"
                                      type="tel"
                                      required
                                      minlength="0"
                                      maxlength="8"
                                      v-model="phone">
                        </b-form-input>
                    </b-input-group>
                </b-form-group>
            </div>

            <div class="col-12 text-right">
                <button class="btn btn-secondary px-3 mr-2"
                        type="reset">
                    Reset
                </button>
                <button class="btn btn-primary px-3"
                        type="submit">
                    Save
                </button>
            </div>
        </form>
    </b-modal>
</template>

<script>
import {
    BModal,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BDropdown,
    BAvatar,
} from "bootstrap-vue";

export default {
    name: "ModalEditAccount",
    components: {
        BModal,
        BFormGroup,
        BFormInput,
        BInputGroup,
        BDropdown,
        BAvatar,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    data () {
        return {
            isAddingColleague: false,

            avatarLocal: "",
            companyLocal: "",
            usernameLocal: "",
            emailLocal: "",
            countryCodeLocal: "",
            phoneLocal: "",
        };
    },
    computed: {
        user () {
            return this.$store.getters["user/info"];
        },
        avatar: {
            get () {
                if (this.avatarLocal) {
                    return this.avatarLocal;
                }
                return this.user?.avatar ?? "";
            },
            set (val) {
                this.avatarLocal = val;
            },
        },
        company: {
            get () {
                if (this.companyLocal) {
                    return this.companyLocal;
                }
                return this.user?.company ?? "";
            },
            set (val) {
                this.companyLocal = val;
            },
        },
        username: {
            get () {
                if (this.usernameLocal) {
                    return this.usernameLocal;
                }
                return this.user?.username ?? "";
            },
            set (val) {
                this.usernameLocal = val;
            },
        },
        email: {
            get () {
                if (this.emailLocal) {
                    return this.emailLocal;
                }
                return this.user?.email ?? "";
            },
            set (val) {
                this.emailLocal = val;
            },
        },
        countryCode: {
            get () {
                if (this.countryCodeLocal) {
                    return this.countryCodeLocal;
                }
                return this.user?.countryCode ?? "";
            },
            set (val) {
                this.countryCodeLocal = val;
            },
        },
        phone: {
            get () {
                if (this.phoneLocal) {
                    return this.phoneLocal;
                }
                return this.user?.phone ?? "";
            },
            set (val) {
                this.phoneLocal = val;
            },
        },
    },
    methods: {
        hideModal () {
            this.$emit("input", false);
        },
        async handleAvatar (e) {
            const files = Array.from(e.target.files || e.dataTransfer.files);
            if (files.length === 0) {
                return;
            }
            this.avatarLocal = await new Promise(
                resolve => {
                    const reader = new FileReader();
                    reader.addEventListener(
                        "loadend",
                        (e) => {
                            resolve(e.target.result);
                        }
                    );
                    reader.readAsDataURL(files[0]);
                }
            );
        },
        reset () {
            this.avatarLocal = "";
            this.companyLocal = "";
            this.usernameLocal = "";
            this.emailLocal = "";
            this.phoneLocal = "";
        },
        async save () {
            const { avatarLocal, companyLocal, usernameLocal, emailLocal, phoneLocal, } = this;
            const payload = {};
            if (avatarLocal) {
                payload.avatar = avatarLocal;
            }
            if (companyLocal) {
                payload.company = companyLocal;
            }
            if (usernameLocal) {
                payload.username = usernameLocal;
            }
            if (emailLocal) {
                payload.email = emailLocal;
            }
            if (phoneLocal) {
                payload.phone = phoneLocal;
            }
            await this.$store.dispatch(
                "user/update",
                payload
            );
            this.hideModal();
            this.reset();
        },
    },
}
</script>

<style lang="scss" scoped>
.btn-link {
    cursor: pointer;
}
</style>
