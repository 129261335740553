<template>
    <div>
        <button class="btn-account btn btn-info border-0 text-center d-flex flex-column flex-nowrap align-items-center mb-3"
                v-on:click="isModalAccountOpen = true">
            <b-avatar class="font-weight-bolder shadow-sm mb-2"
                      variant="light"
                      size="52"
                      v-bind:src="avatar">
                FL
            </b-avatar>
            <span class="company-name text-nowrap overflow-hidden w-100">
                Flinder
            </span>
        </button>
        <modal-account v-model="isModalAccountOpen"></modal-account>
    </div>
</template>

<script>
import { BAvatar, } from "bootstrap-vue";
import ModalAccount from "./ModalAccount";
export default {
    name: "ButtonAccount",
    components: {
        BAvatar,
        ModalAccount,
    },
    data () {
        return {
            isModalAccountOpen: false,
        };
    },
    computed: {
        user () {
            return this.$store.getters["user/info"];
        },
        avatar () {
            return this.user?.avatar ?? "";
        },
        company () {
            return this.user?.company ?? "";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.btn {
    width: 100px;
    transition:
        transform 300ms ease-in-out,
        box-shadow 300ms ease-in-out,
        font-weight 150ms ease-in-out;

    &.active {
        transform: translate(-10px);
        box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
        font-weight: bolder;
    }
}

.company-name {
    text-overflow: ellipsis;
}
</style>
